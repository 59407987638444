import React from "react";
import Header from "../components/header";
import Nav from "../components/nav";
import About from "../components/about";
import Projects from "../components/projects";
import Footer from "../components/footer";

function Home() {
  return (
    <>
      <div id="page" className="s-pagewrap">
        <Nav />
        <Header />
        <About />
        <Projects />
        <Footer />
      </div>
    </>
  );
}
export default Home;
