import React from "react";
import logo from "../assets/img/logo.svg" ;
function Nav() {
  return (
    <>
 <header className="s-header">
            <div className="row s-header__inner">
                <div className="s-header__block">
                    <div className="s-header__logo">
                        <a className="logo" href="index.html">
                            <img src={logo} alt="Homepage"/>
                        </a>
                    </div>
                    <a className="s-header__menu-toggle" href="#0"><span>Menu</span></a>
                </div> 
                <nav className="s-header__nav">    
                    <ul className="s-header__menu-links">
                        <li className="current"><a className="smoothscroll" href="#intro">Intro</a></li>
                        <li><a className="smoothscroll" href="#about">About</a></li>
                        <li><a className="smoothscroll" href="#works">Works</a></li>
                        <li><a className="smoothscroll" href="#footer">Contact</a></li>
                    </ul> 
                </nav>

            </div>
        </header>
    </>
  );
}

export default Nav;